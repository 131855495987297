import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Text, Title } from './../Typography';
import { ReactNode } from 'react';

export const CustomTab = styled.div<{
  children?: ReactNode;
  className?: string;
}>`
  cursor: grab;

  .react-tabs__tab-panel--selected {
    display: inline-block;
  }
  .react-tabs__tab {
    border: 0;
    bottom: 0;
    border-bottom: 3px solid rgba(1, 14, 84, 0.5);
    margin: 0;
    padding: 10px 0;
    white-space: nowrap;
    color: rgba(1, 14, 84, 0.5);

    &:focus,
    &:active {
      outline: none;
    }
    :first-child {
      padding: 10px 0;
      :after {
        content: none;
      }
    }
  }
  .react-tabs__tab-list {
    border: 0;
    margin-bottom: 2.5rem;
    display: flex;

    @media (min-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
      > li,
      > div {
        margin-bottom: 1rem;
        &:first-child {
          margin-left: 2rem;
        }
        &:last-child {
          margin-right: 2rem;
        }
      }
    }
  }
  .react-tabs__tab--selected {
    opacity: 1;
    border-bottom-color: #010e54;
    color: #010e54;
  }
  .react-tabs__tab:hover {
    opacity: 1;
    cursor: pointer;
    color: #010e54;
  }
`;

export const Heading = tw(Title)`
  font-normal
  `;

export const ColHeading = tw.div`
  align-top
`;

export const ColHeadList = tw.div`
  p-4
  h-70
  flex
  items-center
  ${(p: { line?: string }) => (p.line === 'odd' ? 'bg-gray' : '')}
`;

export const ColData = tw.div`
  flex
  items-center
  h-70
  p-4
  ${(p: { line?: string }) => (p.line === 'odd' ? 'bg-gray' : '')}
`;

export const MobileLabel = tw(Text)`
  font-medium
`;

export const MainTitle = tw(Title)`
  text-center
  mb-9
  md:mb-10
`;
export const SeparatorContainer = styled.div`
  padding: 10px 20px;
  border-bottom: 3px solid rgba(1, 14, 84, 0.5);
`;
export const SeparatorDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: rgba(1, 14, 84, 0.5);
  display: inline-block;
`;

export const TwDivTab = tw.div`
w-full
overflow-y-auto
bg-white z-22
`;

export const StyledTabDiv = styled(TwDivTab)`
  padding-left: 19px;
  padding-right: 19px;

  @media (min-width: 768px) {
    max-width: 1276px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;
